.diamondDrawer {
    z-index: 1400;

    .container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        padding: 16px 24px;
        background: #171717;
        color: #d4d4d4;
        gap: 8px;

        @media (width >= 513px) {
            width: 512px;
            margin: 0 auto;
        }
    }

    .close {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
    }

    .diamond {
        font-size: 20px;
        font-weight: 700;
        margin-top: 8px;
    }

    .confirm {
        width: 100%;
        margin: 8px 0 16px;
        box-sizing: border-box;
    }
}
