.baseBanner {
    font-size: 0;
    width: 100%;
    padding: 16px 0;
    aspect-ratio: 390 / 182;
    overflow: hidden;

    .banner {
        display: initial;
        position: relative;
        border-radius: 16px;
        width: calc(100% - 24px);
    }

    img {
        width: 100%;
        height: auto;
        border-radius: 16px;
    }
}

.skeleton {
    width: 100%;
    aspect-ratio: 390 / 206;
    padding: 16px 0;
    box-sizing: border-box;

    .animate {
        width: 100%;
        height: 100%;
        margin-bottom: 0 !important;
        transform: none !important;
    }
}
