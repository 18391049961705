.chatBody {
    margin-bottom: 8px;
    height: calc(100dvh - 204px);
    overflow-y: auto;

    .dateBox {
        position: sticky;
        top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px;
        z-index: 1;

        .dateString {
            color: #a3a3a3;
            text-align: center;
            border-radius: 100px;
            background: rgb(250 250 250 / 10%);
            backdrop-filter: blur(2px);
            margin: auto;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            padding: 4px 8px;
        }

        .observeIt {
            position: sticky;
            top: 0;
        }
    }
}

.official {
    height: calc(100dvh - 120px);
}

.reply {
    height: calc(100dvh - 308px);
}

.loading {
    display: flex;
    justify-content: center;
    margin: 16px auto;
}
