.curationCard {
    position: relative;
    width: 130px;
    aspect-ratio: 130 / 230;
    border-radius: 8px;
    overflow: hidden;
    flex: 0 0 130px;

    .top {
        width: calc(100% - 16px);
        position: absolute;
        top: 8px;
        left: 8px;
        z-index: 9;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover !important;
    }

    .textbar {
        width: 100%;
        height: 56px;
        padding: 16px 8px 8px;
        box-sizing: border-box;
        background: linear-gradient(
            180deg,
            rgb(51 51 51 / 0%) 0%,
            rgb(0 0 0 / 30%) 54.7%,
            rgb(0 0 0 / 40%) 100%
        );
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 9;
        display: flex;
        align-items: flex-end;

        .text {
            color: #fafafa;
            font-size: 12px;
            line-height: 16px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .duration {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        background-color: rgba(0 0 0 / 40%);
        padding: 4px 8px;
        color: #d4d4d4;
        font-size: 12px;
        line-height: 20px;
        border-radius: 16px;
        backdrop-filter: blur(2px);
        z-index: 9;
    }
}
