.close {
    width: 100%;
    text-align: end;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    width: 100%;

    .content {
        color: #525252;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.28px;
    }
}
