.fullScreenPopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    z-index: 1200;
    background-color: #0a0a0a;
    display: flex;
    flex-direction: column;

    @media (width >= 513px) {
        width: 512px;
        left: 50%;
        transform: translateX(-50%) !important;
    }

    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        height: 56px;
        box-sizing: border-box;
        font-size: 16px;
    }

    .main {
        height: 0;
        flex: 1;
    }
}
