.cpBox {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin: 8px 28px 0 16px;
    gap: 4px;

    .cpAvatar {
        align-self: flex-start;
        width: 30px;
        height: 30px;
    }

    .date {
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        color: #737373;
    }
}
