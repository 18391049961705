.searchInput {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px 16px;
    border-radius: 8px;
    border: 1px solid #404040;
    background-color: #171717;
    box-sizing: border-box;
    flex: 1;

    input {
        width: 100%;
        padding: 0;
        background-color: #171717;
        color: #d4d4d4;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        border: none;

        &:focus-visible {
            outline: none;
        }
    }
}

.searchPopup {
    .topBar {
        display: flex;
        gap: 12px;
        padding: 8px 16px;
        box-sizing: border-box;

        .closeBtn {
            height: 44px;
            line-height: 44px;
            font-size: 16px;
            font-weight: 700;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    .resultContainer {
        display: flex;
        width: 100%;
        height: calc(100dvh - 134px);
        overflow: scroll;
        box-sizing: border-box;

        .resultList {
            display: flex;
            flex-direction: column;
            flex: 1;

            .resultInfo {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-shrink: 1;
                line-height: normal;
                padding: 8px 16px;
                box-sizing: border-box;
            }
        }

        .noData {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #525252;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
        }
    }
}

.resultCpCard {
    display: flex;
    align-items: center;
    gap: 8px;

    .info {
        .nickname {
            width: calc(100dvw - 110px);
            display: flex;
            align-items: center;
            color: #d4d4d4;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @media (width >=513px) {
                width: calc(512px - 110px);
            }
        }

        .account {
            width: calc(100dvw - 110px);
            display: flex;
            align-items: center;
            color: #525252;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @media (width >=513px) {
                width: calc(512px - 110px);
            }
        }
    }
}

.highLine {
    color: #dc2626;
}