.cpImgCard {
    width: 50%;
    box-sizing: border-box;
    padding: 10px;

    .cover {
        position: relative;
        width: 100%;
        padding-top: 100%;

        .img {
            border-radius: 10px;
            position: absolute !important;
            top: 0;
            left: 0;
            width: 100% !important;
            height: 100% !important;
        }

        .icon {
            position: absolute;
            left: 4px;
            top: 4px;
            z-index: 2;
        }

        // &.small {
        //     width: 120px !important;
        // }

        // &.middle {
        //     width: 170px !important;
        // }
    }

    .info {
        height: 68px;
        width: 120px;

        .nickname {
            text-align: left;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            max-height: 4.5em;
            color: #d4d4d4;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
        }

        .accountId {
            color: #737373;
            font-size: 14px;
            line-height: 20px;
        }
    }

    // &.small {
    //     width: 120px !important;
    // }

    // &.middle {
    //     width: 170px !important;
    // }
}