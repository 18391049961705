.sticker {
    width: 100%;
    position: fixed;
    bottom: 16px;
    padding: 0 0 16px;
    box-sizing: border-box;
    background-color: #0a0a0a;
    z-index: 1;

    .slick {
        :global {
            .ui-slick-tabs-container {
                padding: 12px 0;
                margin: 0 16px;
                border-bottom: none;
                box-sizing: border-box;

                .ui-slick-li {
                    height: 20px;

                    .ui-slick-button {
                        line-height: 20px;
                        height: 20px;
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    .stickerList {
        font-size: 12px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        margin-top: 4px;
        height: 224px;
        overflow-y: auto;

        &.disabled {
            opacity: 0.6;
        }

        .itemSticker {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 4px;
            color: #d4d4d4;
        }

        .stickerImg {
            padding: 2px;

            img {
                display: block;
                object-fit: contain;
            }
        }

        .name {
            margin: 8px 0;
        }

        .diamond {
            display: flex;
            align-items: center;
            gap: 2px;
            color: #525252;
        }
    }
}
