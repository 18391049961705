.holder {
    height: 108px;
}

.replyHolder {
    height: 188px;
}

.chatFooter {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 8px 0 24px;
    box-shadow: 0 -1px 0 0 #222;
    background-color: #0a0a0a;
    z-index: 1;

    &.chatFooterWithSticker {
        bottom: 300px;
    }

    &.chatReply {
        box-sizing: border-box;
        height: 180px;
    }

    .photo {
        padding: 0 16px;
    }

    .input {
        display: flex;
        align-items: center;
        border-radius: 8px;
        border: 1px solid #404040;
        background-color: #171717;
        height: 44px;
        flex: 1;
        padding-left: 16px;

        textarea {
            background: none;
            border: none;
            outline: none;
            flex: 1;
            color: #d4d4d4;
            resize: none;
            padding-top: 16px;
            min-width: 0;
            width: 100%;
        }

        .gift {
            padding: 0 16px;
        }
    }

    .send {
        padding: 0 16px;
    }
}

.group {
    display: flex;
    align-items: center;
    height: 44px;
    box-sizing: border-box;
}
