.content {
    font-size: 16px;
    font-weight: 400;
    color: #d4d4d4;
    word-break: break-all;
    line-height: 24px;

    p {
        padding: 0 16px;
    }

    .topBorder {
        width: 100%;
        height: 8px;
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
    }

    .bottomBorder {
        width: 100%;
        height: 8px;
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
    }
}

.user {
    position: relative;

    p {
        background-color: rgb(153 27 27 / 70%);
    }

    .topBorder {
        background-color: rgb(153 27 27 / 70%);
    }

    .bottomBorder {
        background-color: rgb(153 27 27 / 70%);
    }
}

.cp {
    p {
        background-color: #171717;
    }

    .topBorder {
        background-color: #171717;
    }

    .bottomBorder {
        background-color: #171717;
    }
}
