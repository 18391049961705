.noData {
    padding: 64px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 32px;

    .title {
        color: #525252;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.28px;
    }
}
