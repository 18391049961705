.videoBox {
    border-radius: 16px;
    overflow: hidden;
    background-color: #171717;

    .video {
        max-width: 300px;
        overflow: hidden;
    }

    .videoSize {
        position: relative;
        width: 100%;
        max-width: 248px;
        overflow: hidden;

        .fit {
            max-width: 248px;
        }

        .play {
            position: absolute;
            inset: 0;
            margin: auto;
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgb(216 216 216 / 30%);
            border-radius: 99em;
        }

        .mediaImg {
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            object-fit: contain;
        }
    }
}

.noted {
    display: flex;
    align-items: center;
    background-color: #171717;
    font-size: 12px;
    padding: 16px;
    justify-content: space-between;

    .diamond {
        color: #525252;
        display: flex;
        align-items: center;
        gap: 4px;
    }

    img {
        max-width: 144px;
        max-height: 144px;
    }
}
