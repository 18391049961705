.mediaDetail {
    position: relative;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .box {
        position: relative;

        .play {
            position: absolute;
            inset: 0;
            margin: auto;
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgb(216 216 216 / 30%);
            border-radius: 99em;
        }
    }

    img {
        width: 100%;
        height: auto;
        max-height: calc(100dvh - 100px);
        object-fit: contain;
    }

    .media {
        max-height: calc(100dvh - 100px);
        text-align: center;
    }

    .bottom {
        position: absolute;
        bottom: 24px;
        width: 100%;
        color: #d4d4d4;
        display: flex;
        align-items: center;
        justify-content: center;

        .btn {
            border: 1px solid #d4d4d4;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 16px;
            gap: 4px;
        }
    }
}

.title {
    margin-left: 24px;
    text-align: center;

    .name {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
    }

    .time {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: #525252;
    }
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 56px);
    position: absolute;
    top: 0;
    width: 100%;
}
