.groupSend {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #d4d4d4;
    margin-right: 8px;
}

.groupNoted {
    background-color: #262626;
    color: #d4d4d4;
    padding: 16px;
    position: relative;

    .title {
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        color: #fafafa;
    }

    .close {
        position: absolute;
        right: 16px;
        top: 16px;
    }

    .description {
        margin-top: 16px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: #d4d4d4;
    }

    .content {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: #737373;
        margin-top: 12px;

        .red {
            color: #ef4444;
        }
    }

    .cta {
        display: flex;
        gap: 16px;
        margin-bottom: 8px;
        margin-top: 16px;

        .btn {
            background-color: #d4d4d4;
            color: #0a0a0a;
            border-radius: 12px;
            width: 100%;
            text-align: center;
            cursor: pointer;
            line-height: 50px;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            position: relative;

            .hiddenInput {
                opacity: 0;
                position: absolute;
                width: 100%;
                left: 0;
                top: 0;
                height: 50px;
            }
        }
    }
}

.textPopup {
    background-color: #262626;
    color: #d4d4d4;
    padding: 16px;
    position: relative;

    .title {
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        color: #fafafa;
    }

    .close {
        position: absolute;
        right: 16px;
        top: 16px;
    }

    .textArea {
        margin: 24px auto;

        .textarea {
            min-height: 144px;
        }
    }

    .textBtn {
        background-color: #d4d4d4;
        color: #0a0a0a;
        border-radius: 12px;
        width: 100%;
        text-align: center;
        cursor: pointer;
        line-height: 50px;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        position: relative;
        margin-bottom: 8px;
    }
}

.preview {
    position: relative;
    height: 100%;
    text-align: center;
    font-size: 16px;

    .bottom {
        position: absolute;
        bottom: 24px;
        width: 100%;
        color: #d4d4d4;
        display: flex;
        align-items: center;
        justify-content: center;

        .btn {
            border: 1px solid #d4d4d4;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 16px;
            gap: 4px;
        }
    }

    .loadingMask {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100dvh;
        background-color: rgb(0 0 0 / 80%);
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .loading {
            width: 100%;
        }
    }

    .imageLimit {
        width: auto;
        max-height: calc(100% - 100px);
        max-width: 100%;
    }

    .videoLimit {
        max-height: calc(100% - 100px);
    }
}

.mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10000;
    background-color: rgb(10 10 10 / 70%);
    display: flex;
    align-items: center;
    justify-content: center;
}
