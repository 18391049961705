.baseTab:global(.MuiTabs-root) {
    width: 230px;
    min-height: 48px;
    height: 48px;
    margin: 0 auto;
    background-color: #0a0a0a;
    padding: 1px;
    border-radius: 12px;
    border: 1px solid rgb(216 216 216 / 30%);

    :global {
        .MuiTabs-flexContainer {
            padding: 0 4px;
        }

        .MuiTabs-indicator {
            width: 100%;
            display: flex;
            align-items: center;
            inset: 0;
            height: auto;
            background: none;

            &::after {
                content: '';
                display: block;
                width: 100%;
                height: 36px;
                position: absolute;
                border-radius: 8px;
                background-color: #d4d4d4;
            }
        }

        .MuiButtonBase-root {
            .MuiTabs-indicator {
                z-index: -1;
            }
        }
    }

    .baseTabBtn {
        height: 48px;
        line-height: 48px;
        color: #5f5f5f;
        font-size: 14px;
        font-weight: 700;
        font-family: 'Noto Sans TC', sans-serif;
        white-space: nowrap;
        text-transform: initial;
        padding: 8px 16px;
        max-width: initial;
        min-width: initial;
        min-height: initial;
        transition: color 0.4s;
        z-index: 1;

        &:global(.Mui-selected) {
            color: #0a0a0a;
        }
    }

    &.outline {
        padding: 0;
        border-radius: 18px;
        background-color: initial;
        border: 1px solid #4489ff;

        .baseTabBtn {
            height: 18px;
            color: #4489ff;

            &:global(.Mui-selected) {
                color: #fff;
            }
        }

        :global {
            .MuiTabs-indicator {
                &::after {
                    height: 18px;
                    background-color: #4489ff;
                    border-radius: 0;
                }
            }
        }
    }
}
