.title {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    width: 144px;
}

.tool {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    color: #d4d4d4;

    .selector {
        display: flex;
        gap: 24px;

        .selectBtn {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
        }
    }

    .count {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    }
}

.list {
    padding: 16px;
    overflow-y: auto;
    height: calc(100vh - 222px);

    .item {
        margin-bottom: 16px;
        display: flex;
        gap: 8px;
        line-height: 20px;
        align-items: center;

        &:nth-last-child(1) {
            margin-bottom: 0;
        }

        .info {
            width: calc(100% - 122px);
            flex: 1;

            .nameBar {
                display: flex;
                align-items: center;
                gap: 8px;

                .name {
                    max-width: calc(100% - 16px);
                    font-size: 16px;
                    font-weight: 700;
                    color: #d4d4d4;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }

            .online {
                background-color: #84cc16;
                width: 8px;
                height: 8px;
                border-radius: 99em;
            }

            .description {
                font-size: 14px;
                color: #525252;
                font-weight: 400;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                height: 20px;
            }
        }

        .field {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;

            .time {
                font-size: 12px;
                font-weight: 400;
                color: #525252;
            }

            .unRead {
                border-radius: 99em;
                color: #0a0a0a;
                background-color: #e5e5e5b2;
                font-size: 12px;
                font-weight: 700;
                width: 20px;
                height: 20px;
                line-height: 20px;
                text-align: center;
            }

            .holder {
                height: 20px;
            }
        }

        .cta {
            width: 60px;
            background-color: #171717;
            color: #d9d9d9;
            border-radius: 12px;
            line-height: 36px;
            text-align: center;
        }
    }
}

.noData {
    text-align: center;
    padding: 32px 0;
    height: calc(100dvh - 300px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        opacity: 0.6;
    }

    .meta {
        font-size: 14px;
        color: #737373;
        font-weight: 500;
        line-height: 20px;
        padding: 0 16px;
    }
}

.loading {
    display: flex;
    justify-content: center;
    margin: 16px auto;
}

.listEnd {
    color: #666;
    padding: 16px 0;
    text-align: center;
    font-size: 14px;
    letter-spacing: -0.36px;
    line-height: normal;
}

.sendHolder {
    height: 94px;
}

.send {
    background: #262626;
    box-shadow: 0 -1px 0 0 #222;
    padding: 12px 16px 32px;
    position: fixed;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;

    .btn {
        border-radius: 12px;
        background: #d4d4d4;
        color: #0a0a0a;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 50px;
        text-align: center;
    }
}
