.albumVideoList {
    height: 80dvh;
    background-color: #0a0a0a;
    border-radius: 16px 16px 0 0;
    padding-top: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    @media (width >= 513px) {
        position: relative;
        width: 512px;
        margin: 0 auto;
    }

    .header {
        height: 24px;
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .text {
            color: #d4d4d4;
            font-size: 16px;
            font-weight: 700;
            line-height: 112.5%;
        }
    }

    .videoList {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 1px;
        overflow: auto;

        .albumVideo {
            width: calc(100% / 3 - (2px / 3));
            aspect-ratio: 130 / 230;
        }

        .noData {
            display: flex;
            height: 100%;
            width: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;
            color: #525252;

            .title {
                font-size: 16px;
                font-weight: 700;
                line-height: 18px;
            }

            .description {
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}

.endBar {
    display: flex;
    justify-content: center;
    width: 100%;

    .listEnd {
        height: 52px;
        line-height: 52px;
        color: #666;
        text-align: center;
        font-size: 14px;
        letter-spacing: -0.36px;
        box-sizing: border-box;
    }
}
