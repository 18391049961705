.label {
    display: block;
    font-size: 14px;
    color: #d4d4d4;
    font-weight: 400;
    margin-bottom: 8px;
}

.inputBlock {
    display: flex;
    align-items: center;
}

.baseInput {
    border-radius: 8px;
    background-color: #171717;
    outline: none;
    color: #737373;
    padding: 12px 16px;
    font-weight: 400;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #404040;
    height: 44px;

    ::placeholder {
        color: #737373;
    }

    &:disabled {
        color: #404040;
        border-color: #404040;
        background-color: #262626;
    }
}

.error {
    border: 1px solid #ef4444;
}

.errorMessage {
    margin-top: 4px;
    font-size: 12px;
    min-height: 16px;
    font-weight: 700;
    line-height: 16px;
    color: #ef4444;
}
