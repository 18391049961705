.cpAvatar {
    width: 46px;
    height: 46px;
    border-radius: 50%;

    .avatarImg {
        border-radius: 50%;
        border: 2px solid;
        box-sizing: border-box;
        object-fit: cover;
    }

    &.evil {
        .avatarImg {
            border-color: #ab3d3de5;
        }
    }

    &.angel {
        .avatarImg {
            border-color: #d4d4d4;
        }
    }
}
