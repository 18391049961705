.cpInfoCard {
    display: flex;
    align-items: center;
    column-gap: 8px;
    overflow: hidden;

    .info {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .text {
            display: flex;
            align-items: center;
            column-gap: 4px;

            .userName {
                display: block;
                color: #d4d4d4;
                font-size: 16px;
                font-weight: 700;
                line-height: 18px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                &.onlineType {
                    max-width: calc(100% - 20px);
                }
            }

            .online {
                width: 8px;
                height: 8px;
                background-color: #60b527;
                border-radius: 50%;
            }
        }

        .subText {
            color: #5f5f5f;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.28px;
            line-height: normal;
        }

        &.light {
            .text .userName,
            .subText {
                color: #fafafa;
            }
        }
    }
}
