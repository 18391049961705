.header {
    height: 56px;

    .fixed {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;
        display: flex;
        padding: 8px 16px;
        height: 56px;
        line-height: 0;
        background-color: #0a0a0a;
        z-index: 100;

        @media (width >=513px) {
            width: 512px;
            left: 50%;
            transform: translateX(-50%);
        }

        .tool {
            display: flex;
            gap: 16px;
            line-height: 0;
            align-items: center;

            .searchIcon {
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
}