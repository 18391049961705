.layoutContainer {
    background: #0a0a0a;
    min-height: 100vh;

    @media (width >= 513px) {
        position: relative;
        width: 512px;
        min-height: 100dvh;
        margin: 0 auto;
    }
}
