.uploadProgress {
    position: fixed;
    z-index: 1500;
    width: 100%;
    height: 100dvh;
    background-color: rgb(0 0 0 / 80%);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;

    .box {
        background-color: #262626;
        border-radius: 16px;
        width: calc(100% - 32px);
        margin: auto;
        max-width: 320px;
        text-align: center;
        padding: 16px;
        box-sizing: border-box;

        .title {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            color: #fafafa;
            margin-bottom: 12px;
        }

        .content {
            color: #a3a3a3;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 16px;
        }

        .progress {
            .text {
                color: #f87171;
                font-size: 12px;
                font-weight: 700;
                line-height: 16px;
                text-align: left;
                margin-bottom: 4px;
            }

            .percent {
                width: 100%;
                height: 4px;
                background-color: #404040;
                border-radius: 100px;
                overflow: hidden;

                .line {
                    width: 0%;
                    height: 4px;
                    border-radius: 100px;
                    background: linear-gradient(90deg, #ea580c 0%, #991b1b 100%);
                }
            }
        }
    }
}
