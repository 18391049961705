.login {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 24px 8px;
    background-color: #262626;
    z-index: 100;
    border-radius: 16px 16px 0 0;

    @media (width >= 513px) {
        width: 512px;
        margin: 0 auto;
    }

    .close {
        position: absolute;
        top: 24px;
        right: 24px;
    }

    .logo {
        margin-top: 16px;
        padding: 28px;
    }

    .title {
        color: #d4d4d4;
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        padding: 0 16px;
        margin-bottom: 16px;
    }

    .email {
        margin: 8px 16px 24px;
    }

    .btn {
        padding: 0 16px;

        .or {
            color: #737373;
            font-size: 14px;
            font-weight: 700;
            text-align: center;
            margin: 16px 0;
        }

        .googleBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
        }
    }

    .terms {
        color: #737373;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        padding: 16px;

        a {
            text-decoration: underline;
            color: #737373;
            font-weight: 700;
        }
    }
}
