.chat {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100dvh;
    z-index: 1000;
    background-color: #0a0a0a;

    @media (width >= 513px) {
        width: 512px;
        left: 50%;
        transform: translateX(-50%) !important;
    }
}
