.contentContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
        color: #d4d4d4;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
    }

    .content {
        color: #525252;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }
}
