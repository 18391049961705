.mediaPopup {
    display: flex;
    align-items: center;
    color: #fff;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 32px 16px;
    background-color: #171717;
    z-index: 100;
    border-radius: 16px 16px 0 0;
    justify-content: space-around;
    text-align: center;

    @media (width >= 513px) {
        width: 512px;
        margin: 0 auto;
    }

    .photo {
        position: relative;
        width: 100%;

        .hiddenInput {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        }
    }

    .voice {
        width: 100%;
    }

    p {
        margin-top: 8px;
    }
}

.preview {
    position: relative;
    height: 100%;
    text-align: center;

    .bottom {
        position: absolute;
        bottom: 24px;
        width: 100%;
        color: #d4d4d4;
        display: flex;
        align-items: center;
        justify-content: center;

        .btn {
            border: 1px solid #d4d4d4;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 16px;
            gap: 4px;
        }
    }

    .loadingMask {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100dvh;
        background-color: rgb(0 0 0 / 80%);
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .loading {
            width: 100%;
        }
    }

    .imageLimit {
        width: auto;
        max-height: calc(100% - 100px);
        max-width: 100%;
    }

    .videoLimit {
        max-height: calc(100% - 100px);
    }
}
