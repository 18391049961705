.quote {
    display: flex;
    align-items: center;
    border-right: 2px solid #737373;
    margin: 12px;
    justify-content: flex-end;
    gap: 8px;
    padding: 12px;

    .content {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        color: #737373;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-clamp: 3;
        -webkit-line-clamp: 3;
        padding-left: 40px;
    }

    .img {
        width: 72px;
        height: 72px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: auto;
            max-width: 100%;
            max-height: 100%;
            border-radius: 12px;
        }
    }
}
