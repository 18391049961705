.userBox {
    display: flex;
    justify-content: flex-end;
    margin: 8px 16px 0 28px;
    gap: 4px;
    align-items: center;
    align-items: flex-end;

    .info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .tick {
            height: 16px;
        }

        .date {
            font-size: 12px;
            line-height: 16px;
            font-weight: 400;
            color: #737373;
        }
    }
}
