.baseDatePicker {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #d4d4d4;
    padding: 16px;
    background-color: #171717;
    border-radius: 8px;
    border: 1px solid #404040;
    width: 100%;
    box-sizing: border-box;
    color-scheme: dark;
    outline: none;

    input[type='date']::-webkit-inner-spin-button,
    input[type='date']::-webkit-calendar-picker-indicator {
        display: none;
        appearance: none;
    }

    &::placeholder {
        color: #d4d4d4;
    }
}

.label {
    display: block;
    font-size: 14px;
    color: #d4d4d4;
    font-weight: 400;
    margin-bottom: 8px;
}
