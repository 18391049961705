.albumPage {
    width: 100%;
    text-align: center;

    .albumList {
        text-align: left;
    }
}

.listEnd {
    height: 52px;
    line-height: 52px;
    color: #666;
    text-align: center;
    font-size: 14px;
    letter-spacing: -0.36px;
    box-sizing: border-box;
}
