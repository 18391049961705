.infoFooter {
    padding: 32px 24px 16px;
    color: #525252;
    font-size: 12px;
    font-weight: 400;

    .footer {
        position: relative;
        border-top: 1px solid #171717;
        padding-top: 16px;
        display: flex;
        flex-direction: column;
        row-gap: 16px;

        .slogan {
            color: #d4d4d4;
            font-size: 14px;
            font-weight: 400;
            line-height: normal;
        }

        .companyProfile {
            display: flex;
            flex-direction: column;
            row-gap: 4px;
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
        }

        .sns {
            display: flex;
            align-items: center;
            column-gap: 16px;

            a {
                font-size: 0;
            }
        }

        .earn {
            position: absolute;
            top: 22px;
            right: 0;
            height: 36px;
            padding: 8px 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2px;
            box-sizing: border-box;
            background-color: #fafafa;
            color: #0a0a0a;
            font-size: 14px;
            font-weight: 700;
            line-height: normal;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }
    }
}
