.title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.priceSetting {
    padding: 0 16px 16px;
    box-sizing: border-box;

    .itemSection {
        font-size: 14px;
        display: flex;
        align-items: center;
        padding: 16px;
        margin: 16px 0;
        background: #171717;
        box-shadow: 0px 1px 0px 0px #3b3b3b;
        border-radius: 12px;
        cursor: pointer;

        .label {
            color: #d4d4d4;
            margin-left: 8px;
        }
        .value {
            color: #737373;
            margin: 0 8px 0 auto;
        }
    }

    .remark {
        font-size: 12px;
        font-weight: 400;
        color: #525252;
        text-align: center;
    }
}
