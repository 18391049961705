.albumCard {
    display: flex;
    column-gap: 12px;
    padding: 8px 16px;
    cursor: pointer;

    .imgBox {
        position: relative;
        width: 88px;
        height: 116px;
        aspect-ratio: 88 / 116;
        border-radius: 12px;
        overflow: hidden;

        img {
            height: 100%;
            object-fit: cover;
        }
    }

    .infoCard {
        flex: 1;
        display: flex;
        flex-direction: column;
        row-gap: 2px;
        color: #d4d4d4;

        .title {
            font-size: 16px;
            font-weight: 700;
            line-height: normal;
        }

        .content {
            flex: 1;
            font-size: 14px;
            line-height: normal;
        }

        .tagBar {
            margin-top: 2px;
            display: flex;
            flex-wrap: wrap;
            gap: 4px;

            .tag {
                padding: 4px 8px;
                box-sizing: border-box;
                border-radius: 16px;
                background-color: rgb(217 217 217 / 10%);
                color: #d4d4d4;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
            }
        }

        .infoBar {
            display: flex;
            align-items: center;
            column-gap: 12px;
            font-size: 12px;

            .count,
            .diamond {
                display: flex;
                align-items: center;
            }

            .new {
                color: #ef4444;
            }
        }
    }
}
