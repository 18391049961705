.videoPage {
    text-align: center;
}

.videoList {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1px;

    .cpVideo {
        width: calc(100% / 3 - (2px / 3));
        height: 100%;
    }
}

.listEnd {
    height: 52px;
    line-height: 52px;
    color: #666;
    text-align: center;
    font-size: 14px;
    letter-spacing: -0.36px;
    box-sizing: border-box;
}
