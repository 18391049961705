.chatReply {
    margin: 0 16px 8px;
    border-left: 2px #737373 solid;
    padding-left: 8px;
    display: flex;
    align-items: center;
    height: 96px;
    box-sizing: border-box;
    justify-content: space-between;

    .replyContent {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        color: #737373;

        .img {
            width: 72px;
            height: 72px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: auto;
                max-width: 100%;
                max-height: 100%;
                border-radius: 12px;
            }
        }
    }
}
