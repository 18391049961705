.selectCard {
    position: relative;
    border: 1px solid #404040;
    background-color: #262626;
    border-radius: 12px;

    .label {
        color: #171717;
        font-size: 12px;
        font-weight: 700;
        position: absolute;
        top: -1px;
        right: -1px;
        background-color: #d4d4d4;
        line-height: normal;
        border-radius: 0 12px 0 4px;
        padding: 2px 12px;
    }
}

.selected {
    background-color: #404040;
    border: 1px solid #d4d4d4;
}
