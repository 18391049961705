.validateEmail {
    padding: 16px;

    .title {
        font-size: 24px;
        color: #d4d4d4;
        font-weight: 700;
        line-height: 36px;
        margin-bottom: 8px;
    }

    .subTip {
        font-size: 12px;
        color: #737373;
        margin-bottom: 32px;
    }

    .label {
        font-size: 14px;
        font-weight: 400;
        color: #d4d4d4;
        margin-bottom: 8px;
    }

    .validateCode {
        display: flex;
        gap: 8px;

        .input {
            width: 100%;
            height: 44px;
        }

        .countDownBtn {
            flex: 1;

            .countBtn {
                min-width: 40px;
                text-align: center;
                font-size: 14px;
                font-weight: 700;
                color: #0a0a0a;
                word-break: keep-all;
                line-height: 44px;
            }

            .disabled {
                background-color: #262626;
                color: #404040;
            }
        }
    }

    .submit {
        width: calc(100% - 32px);
        position: absolute;
        box-sizing: border-box;
        bottom: 24px;
    }
}
