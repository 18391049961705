.baseButton {
    border-radius: 12px;
    font-size: 16px;
    font-weight: 700;
    line-height: 48px;
    padding: 0 16px;
    text-align: center;
    cursor: pointer;
}

.normal {
    background: #d4d4d4;
    color: #0a0a0a;
}

.outline {
    border: 1px solid #d4d4d4;
    color: #d4d4d4;
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
}

.disabled {
    opacity: 0.8;
    cursor: not-allowed;
}
