.operationInterface {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100dvw;
    height: 100dvh;
    background-color: #0a0a0a;
    z-index: 11;

    @media (width >=513px) {
        width: 512px;
        left: 50%;
        transform: translateX(-50%);
    }

    .headerOption {
        position: relative;
        height: 44px;
        padding: 0 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        transition: 0.2s all ease-in-out;

        .backButton,
        .morebutton {
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }

        &.hideView {
            opacity: 0;
            z-index: -1;
        }

        .placeholder {
            width: 24px;
        }

        .middleText {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #d4d4d4;
            font-size: 16px;
            font-weight: 700;
        }
    }

    .videoHeader {
        position: absolute;
        width: 100%;
        top: 56px;
        z-index: 2;
    }

    .viewContainer {
        flex: 1;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        .video {
            width: 100%;
        }

        .img {
            position: absolute;
            max-height: 100%;
            width: 100%;
            object-fit: cover;
        }

        .mask {
            position: relative;
            width: 100%;
            height: 100%;
            background-color: rgb(0 0 0 / 30%);
            z-index: 1;
            transition: 0.2s all ease-in-out;

            &.hideView {
                opacity: 0;
                z-index: -1;
            }

            .lockBar {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                align-items: center;
                gap: 4px;
                padding: 12px 16px;
                border-radius: 12px;
                border: 1px solid #d4d4d4;
                color: #d4d4d4;
                cursor: pointer;
                z-index: 2;

                &:hover {
                    opacity: 0.8;
                }
            }
        }

        .likeBtn {
            position: absolute;
            top: 16px;
            left: 16px;
            width: 40px;
            height: 40px;
            background-color: rgb(0 0 0 / 20%);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            z-index: 9;
            transition: 0.2s all ease-in-out;

            &.hideView {
                opacity: 0;
                z-index: -1;
            }

            &.isFollow {
                background-color: #171717;

                svg path {
                    fill: #d4d4d4;
                }
            }
        }
    }

    .footerPlaceholder {
        max-width: 100dvw;
        height: 164px;
        position: relative;

        .footer {
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: rgb(0 0 0 / 80%);
            width: 100%;
            padding: 8px 16px 32px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            box-sizing: border-box;
            transition: 0.2s all ease-in-out;
            z-index: 9;

            .box {
                display: flex;
                color: #d4d4d4;
                line-height: normal;

                .left {
                    max-width: calc(100% - 56px);
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    row-gap: 8px;
                    font-weight: 400;

                    .content {
                        font-size: 14px;
                        letter-spacing: 0.28px;
                        color: #d4d4d4;
                    }

                    .tagBar {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 4px;

                        .tag {
                            padding: 4px 8px;
                            box-sizing: border-box;
                            border-radius: 16px;
                            background-color: #404040;
                            color: #d4d4d4;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 16px;
                        }
                    }

                    .infoBar {
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        line-height: 20px;
                        column-gap: 12px;
                        color: #a3a3a3;

                        .info {
                            display: flex;
                            align-items: center;
                            column-gap: 4px;
                        }
                    }

                    .time {
                        color: #737373;
                        font-size: 12px;
                        line-height: 16px;
                    }
                }

                .right {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    row-gap: 16px;
                    padding: 8px 16px;
                    box-sizing: border-box;
                    font-weight: 700;
                    flex-shrink: 1;

                    .operate {
                        display: flex;
                        flex-direction: column;
                        row-gap: 2px;
                        line-height: normal;
                        font-size: 12px;
                        font-weight: 700;
                        cursor: pointer;

                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }
            }

            .chatBar {
                display: flex;
                align-items: center;
                gap: 8px;
                box-sizing: border-box;

                .chatBtn {
                    height: 44px;
                    padding: 0 16px;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    flex: 1;
                    border-radius: 8px;
                    border: 1px solid #404040;
                    background-color: #262626;
                    color: #737373;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    box-sizing: border-box;
                    cursor: pointer;
                }

                .giftBtn {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: rgb(250 250 250 / 30%);
                    border-radius: 50%;
                    cursor: pointer;
                }
            }
        }

        &.isVideo {
            height: initial;

            .footer {
                position: initial;
            }
        }

        &.hideView .footer {
            opacity: 0;
            z-index: -1;
        }
    }
}

.moreMenu {
    width: 100%;

    .option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    :global {
        .MuiPaper-root {
            width: 250px;
            background-color: #171717;
            color: #d4d4d4;
            border-radius: 16px;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 50%);
        }

        .MuiList-root {
            padding: 0;
        }
    }
}

.iconBtn {
    cursor: pointer;
}