.tabsContainer {
    padding: 12px;
    position: relative;
    box-sizing: border-box;

    .slick {
        transition: transform 0.3s ease;
        position: absolute;
        z-index: -1;
    }

    .tabs {
        display: flex;
        justify-content: space-around;
        border-radius: 30px;
        line-height: 32px;
        font-size: 14px;
        font-weight: 500;
    }

    li {
        width: initial;
        height: 32px;
        display: flex;
        align-items: center;
    }

    .tabButton {
        position: relative;
        width: initial;
        height: 32px;
        padding: 0;
        display: block;
        line-height: 28px;
        border-radius: 30px;
        outline: none;
        box-shadow: none;
        border: none;
        background: none;
        text-align: center;
        color: #4489ff;
        font-size: 14px;
        font-weight: 500;
        font-family: 'PingFang SC', sans-serif;
    }

    .selected {
        transition: color 0.3s;
        color: #fff;
    }
}

.button {
    .slick {
        background-color: #4489ff;
        height: 28px;
        border-radius: 30px;
        display: none;
    }

    .tabButton {
        color: #8d8d8d;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            transform: translate(-50%, -50%) scaleX(0.32);
            transition-duration: 0.2s;
            transition-property: all;
            transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
            background: #4489ff;
            opacity: 0;
            z-index: -1;
            border-radius: 30px;
        }
    }

    .selected {
        position: relative;
        color: #fff;
        z-index: 1;

        &::before {
            transform: translate(-50%, -50%) scaleX(1);
            opacity: 1;
        }
    }
}

.underline {
    border-bottom: 1px #d9d9d9 solid;

    .slick {
        background-color: #fff;
        height: 2px;
        bottom: -2px;
        z-index: 1;
    }

    .tabButton {
        color: #8d8d8d;
    }

    .selected {
        color: #fff;
    }
}

.fixed {
    width: 100%;
    position: fixed;
    top: 54px;
    left: 0;
    z-index: 4;
}

.placeholder {
    width: 100%;
    height: 56px;
}
