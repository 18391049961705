.fcmModal {
    position: fixed;
    top: 16px;
    border-radius: 16px;
    background: var(--system-neutral-800, #262626);
    box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 50%);
    max-width: 360px;
    width: calc(100% - 32px);
    z-index: 1000;
    left: 0;
    right: 0;
    margin: auto;
    padding: 8px 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    .cover {
        margin-right: 16px;

        img {
            border-radius: 99em;
        }
    }

    .content {
        width: calc(100% - 64px);
        line-height: 20px;

        .title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            font-weight: 700;
            color: #d4d4d4;
        }

        .text {
            font-size: 14px;
            font-weight: 400;
            color: #525252;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
