.media {
    border-radius: 16px;
    overflow: hidden;
    background-color: #171717;

    .fit {
        max-width: 248px;
        line-height: 0;
    }

    img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        object-fit: contain;
    }
}

.noted {
    display: flex;
    align-items: center;
    background-color: #171717;
    font-size: 12px;
    padding: 16px;
    justify-content: space-between;

    .diamond {
        color: #525252;
        display: flex;
        align-items: center;
        gap: 4px;
    }

    img {
        max-width: 144px;
        max-height: 144px;
    }
}
