.depositModal {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    background-color: #171717;
    z-index: 100;
    border-radius: 16px 16px 0 0;
    height: calc(100dvh - 172px);
    display: flex;
    flex-direction: column;

    @media (width >= 513px) {
        width: 512px;
        margin: 0 auto;
    }

    .header {
        height: 32px;
        padding-top: 16px;
        font-size: 16px;
        border-radius: 16px 16px 0 0;
        font-weight: 700;
        line-height: 18px;
        text-align: center;
        color: #d4d4d4;
        background-color: #171717;

        .close {
            position: absolute;
            top: 16px;
            right: 16px;
            z-index: 10;
        }
    }

    .content {
        padding: 0 16px 24px;
        overflow-y: auto;

        .myDiamond {
            display: flex;
            justify-content: center;
            gap: 4px;

            .diamondInfo {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .value {
                    font-size: 24px;
                    font-weight: 700;
                    color: #d4d4d4;
                }

                .label {
                    font-size: 12px;
                    font-weight: 700;
                    color: #5f5f5f;
                }
            }
        }

        .paymentMethod {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .title {
                font-size: 16px;
                font-weight: 700;
                line-height: 18px;
                color: #d4d4d4;
            }

            .cards {
                display: flex;
                gap: 4px;

                .card {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 16px;
                    padding: 16px 32px;

                    .label {
                        font-size: 14px;
                        font-weight: 700;
                        color: #d4d4d4;
                    }
                }
            }

            .tips {
                color: #5f5f5f;
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
            }
        }

        .paymentPlan {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .title {
                font-size: 16px;
                font-weight: 700;
                line-height: 18px;
                color: #d4d4d4;
            }

            .cards {
                display: flex;
                flex-wrap: wrap;
                gap: 12px;

                .card {
                    &:first-child {
                        flex-basis: 100%;
                    }

                    flex: 0 1 calc(50% - 6px);
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 8px;
                    padding: 24px 20px 8px;
                    width: 100%;

                    .planDiamond {
                        display: flex;
                        flex-direction: center;
                        gap: 4px;

                        .value {
                            font-size: 24px;
                            font-weight: 700;
                            color: #d4d4d4;
                        }
                    }

                    .label {
                        color: #d35454;
                        font-size: 12px;
                        font-weight: 700;
                    }

                    .price {
                        color: #d4d4d4;
                        font-size: 14px;
                        font-weight: 500;
                        letter-spacing: 0.28px;
                    }
                }
            }

            .tips {
                display: flex;
                flex-direction: column;
                gap: 12px;
                color: #5f5f5f;
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
            }
        }
    }

    .footer {
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: 126px;
        padding: 16px 16px 24px;

        .serviceLink {
            color: #5f5f5f;
            font-size: 14px;
            font-weight: 700;
            text-decoration: underline;
            text-align: center;
        }
    }
}
