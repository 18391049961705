.dramaItem {
    display: flex;
    margin-bottom: 32px;
    gap: 8px;

    .cover {
        display: block;
        width: 120px;
        height: 160px;
        flex-shrink: 0;
        border-radius: 16px;
        object-fit: cover;
    }

    .txt {
        display: flex;
        flex-direction: column;
        line-height: 20px;

        .title {
            color: #fff;
            font-weight: 700;
        }

        .desc {
            margin: 8px 0;
            color: #a4a4a4;
        }

        .bottom {
            display: flex;
            font-size: 12px;
            color: #a4a4a4;
            margin-top: auto;

            .unlockCount {
                color: #0f0;
                margin-left: 8px;
            }
        }
    }
}
